/*

	CSME PRO SlideShow Class
	
	Example:
	
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html>
<head>
<script type="text/javascript" src="slideshow.js"></script>
</head>
<body>	
	<div id="div1" style="border: 1px solid black; width: 500px; height: 227px;"></div>
	<script>
	var sh1 = new SlideShow( document.getElementById("div1") ,500,227 , 2000 , 1 , true ); 
	sh1.add("http://www.fav.co.il/files/gallery/11696/clear.jpg","http://www.bullstar.co.il/","_self");
	sh1.add("http://www.fav.co.il/files/gallery/11696/bullstar_smalllogo.jpg","http://www.google.co.il/","_blank");
	sh1.add("http://www.fav.co.il/files/gallery/11696/bullstar_logo.jpg","","");
	sh1.add("http://www.fav.co.il/files/gallery/11696/zigota_popup.jpg","","");
	</script>	
	<p>
		<button onclick="sh1.next();">NEXT</button>&nbsp;
		<button onclick="sh1.back();">BACK</button>&nbsp;
		<button onclick="sh1.play();">PLAY</button>&nbsp;
		<button onclick="sh1.stop();">STOP</button>&nbsp;		
		<button onclick="sh1.speed = 1000;">1 Sec</button>&nbsp;		
		<button onclick="sh1.speed = 10000; alert(sh1.speed);">10 Sec</button>&nbsp;	
		<button onclick="sh2.goto(0);">1</button>&nbsp;			
		<button onclick="sh2.goto(1);">2</button>&nbsp;			
		<button onclick="sh2.goto(2);">3</button>&nbsp;			
		<button onclick="sh2.goto(3);">4</button>&nbsp;	
	</p>	
</body>
</html>	
	
*/

var SlideShow = function(container,maxWidth,maxHeight,speed,fadding,scale) {

	// container - must by an element with id attribute
	// fadding = fadding speed 1 - 10 , the higher = the faster , 0 = no fadding
	
	this.obj = SlideShow;
	this.container = container;
	this.holder = null;
	this.width = maxWidth;
	this.height = maxHeight;
	this.items = new Array();
	this.dummy = 0;
	this.slide = true;
	this.speed = speed;
	this.divFront = null;
	this.divBack = null;
	this.fadding = fadding;
	this.zIndex = 1000;
	this.scale = scale;
	//
	this.swapMode = "no";
	//

	this.obj.prototype.clicked = function() {	
		if ( this.items[this.dummy].url != "" ) {
			if ( this.items[this.dummy].target == "_blank") {
				window.open(this.items[this.dummy].url);
				} else {
				location.href = this.items[this.dummy].url;
				}
			}
		}
	
	this.obj.prototype.index = function() {	
		this.zIndex++;
		return this.zIndex;
		}
		
	this.obj.prototype.workarea = function() {
		this.container.innerHTML = '<div id="'+this.container.id+'_holder" style="height: '+this.height+'px; width:'+this.width+'px; position: relative;"></div>';
		this.holder =  document.getElementById(this.container.id+'_holder');
		var self = this;
		this.holder.onclick = function () { self.clicked(); }
		//
		}
	
	this.obj.prototype.opacity = function(opacity, object) {

		object.style.opacity = (opacity / 100); 
		object.style.MozOpacity = (opacity / 100); 
		object.style.KhtmlOpacity = (opacity /100); 
		object.style.filter = "alpha(opacity=" + (opacity) + ")"; 
		}
	
	this.obj.prototype.fade = function(object,fadeTo) {
		i = Math.round(parseFloat(object.style.opacity) * 100);
		if (!i && i!=0) i = 100;		
		i += ( fadeTo == "out" ? -this.fadding : this.fadding );
		
		if ( i < 0 || i > 100 ) {
			this.opacity( ( i < 0 ? 0 : 100 ) ,object);
			this.swapMode = "no";
			} else {
			this.opacity(i,object);
			var self = this; 
			setTimeout(function(){ self.fade(object,fadeTo); }, 1); 
			}
		}

	this.obj.prototype.addComplete = function(object,url,target) {
		if ( this.items.length == 0 ) {
			this.workarea();
			this.interval();
			}
		o = new Object();
		o.src = object.src;
		o.url = url;
		o.target = target;
		o.id = this.container.id+'_'+this.items.length;		
		o.width = object.width;
		o.height = object.height;
		
		if ( this.scale == true ) {
			o.width = this.width;
			o.height = this.height;
			} else if ( o.width > this.width || o.height > this.height ) {
			
				if ( o.width > this.width ) {	o.height = Math.round((this.width / o.width) * o.height);	o.width = this.width;	}
				if ( o.height > this.height ) {	o.width = Math.round((this.height / o.height) * o.width);	o.height = this.height;	}
				if ( o.width > this.width ) {	o.height = Math.round((this.width / o.width) * o.height);	o.width = this.width;	}
	
			}
		
		o.top = Math.round((this.height - o.height) / 2);
		o.left = Math.round((this.width - o.width) / 2);
	
		o.html = '<img src="'+o.src+'" id="'+o.id+'" style="display: none; position: absolute; top: '+o.top+'px; left:'+o.left+'px; height: '+o.height+'px; width:'+o.width+'px; z-index: '+this.index()+';" />';
		this.holder.innerHTML += o.html;
		this.items[this.items.length] = o;	

		if ( this.items.length == 1 ) {
			document.getElementById(o.id).style.display = "block";
			}
		}
		
	this.obj.prototype.add = function(src,url,target) {
		image = new Image();
		image.src = src;
		var self = this;
		image.onload = function() {	self.addComplete(this,url,target); }
		};

	this.obj.prototype.interval = function() {
		if ( this.slide == true && this.items.length > 1 && this.swapMode == "no" ) {
			lastDummy = this.dummy;
			this.dummy++;
			if (this.dummy >= this.items.length) this.dummy = 0;
			this.display(this.dummy,lastDummy);	
			}
		var self = this; 
		setTimeout(function(){ self.interval(); }, this.speed); 
		}
		
	this.obj.prototype.play = function() {
		this.slide = true;
		}

	this.obj.prototype.stop = function() {
		this.slide = false;	
		}		
		
	this.obj.prototype.next = function() {
		this.stop();
		lastDummy = this.dummy;
		this.dummy++;
		if (this.dummy >= this.items.length) this.dummy = 0;
		this.display(this.dummy,lastDummy);			
		}

	this.obj.prototype.back = function() {
		this.stop();
		lastDummy = this.dummy;
		this.dummy--;
		if (this.dummy < 0 ) this.dummy = this.items.length -1;
		this.display(this.dummy,lastDummy);		
		}

	this.obj.prototype.goto = function(no) {
		this.stop();
		lastDummy = this.dummy;
		this.dummy = no;
		if (this.dummy < 0 ) this.dummy = this.items.length -1;
		if (this.dummy >= this.items.length) this.dummy = 0;		
		this.display(this.dummy,lastDummy);		
		}

	this.obj.prototype.display = function(to,from) {
		
		if ( this.fadding > 0 ) {
			this.swapMode = "yes";
		
			this.index();
			document.getElementById(this.items[from].id).style.zIndex = this.index();
			document.getElementById(this.items[to].id).style.zIndex = this.zIndex - 1;

			document.getElementById(this.items[to].id).style.display = "block";
			document.getElementById(this.items[from].id).style.display = "block";

			this.opacity(0,document.getElementById(this.items[to].id));
			this.opacity(100,document.getElementById(this.items[from].id));
			
			this.fade(document.getElementById(this.items[from].id),"out");
			this.fade(document.getElementById(this.items[to].id),"in");
			
			} else {
			document.getElementById(this.items[from].id).style.display = "none";			
			document.getElementById(this.items[to].id).style.display = "block";			
			document.getElementById(this.items[to].id).style.zIndex = this.index();
			}
		}
		
	};